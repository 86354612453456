import React from 'react';
import Layout from '../components/Layout';
import {useTranslation} from 'react-i18next';
import {Link} from 'gatsby';
import Seo from '../components/Seo';
import {withLocaleProvider} from '../locale/LocaleProvider';

export const SolutionsPage = () => {
  const {t, i18n} = useTranslation();
  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('Solutions:seo:title')}
        description={t('Solutions:seo:description')}
        url={t('Solutions:seo:url')}
      />
      <Layout beforeFooter>
        <div className="breadcrumbs-v3 img-v6">
          <div className="container text-center">
            <p>{t('Solutions:intro:subtitle')}</p>
            <h1>{t('Solutions:intro:title')}</h1>
          </div>
        </div>
        <div className="bg-color-light">
          <div className="container content-sm">
            <div className="headline-center margin-bottom-40">
              <h2>{t('Solutions:contents:title')}</h2>
            </div>
            <div className="lead lead--solutions text-center margin-bottom-40">
              <p
                dangerouslySetInnerHTML={{
                  __html: t('Solutions:contents:text-1'),
                }}
              />
            </div>
            <div className="row margin-bottom-40">
              <div className="col-md-6 sm-margin-bottom-20">
                <div className="service-block-v8">
                  <i className="icon-screen-desktop"></i>
                  <div className="service-block-desc">
                    <h3>{t('Solutions:contents:card-1:title')}</h3>
                    <p>{t('Solutions:contents:card-1:text')}</p>
                    <ul>
                      <li>{t('Solutions:contents:card-1:item-1')}</li>
                      <li>{t('Solutions:contents:card-1:item-2')}</li>
                      <li>{t('Solutions:contents:card-1:item-3')}</li>
                      <li>{t('Solutions:contents:card-1:item-4')}</li>
                    </ul>
                    <hr />
                    <Link
                      to={t('Solutions:contents:card-1:link-url')}
                      className="link-to-courses"
                    >
                      {t('Solutions:contents:card-1:link-text')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 sm-margin-bottom-20">
                <div className="service-block-v8">
                  <i className="icon-screen-desktop"></i>
                  <div className="service-block-desc">
                    <h3>{t('Solutions:contents:card-2:title')}</h3>
                    <p>{t('Solutions:contents:card-2:text')}</p>
                    <ul>
                      <li>{t('Solutions:contents:card-2:item-1')}</li>
                      <li>{t('Solutions:contents:card-2:item-2')}</li>
                      <li>{t('Solutions:contents:card-2:item-3')}</li>
                      <li>{t('Solutions:contents:card-2:item-4')}</li>
                      <li>{t('Solutions:contents:card-2:item-5')}</li>
                      <li>{t('Solutions:contents:card-2:item-6')}</li>
                      <li>{t('Solutions:contents:card-2:item-7')}</li>
                    </ul>
                    <hr />
                    <Link
                      to={t('Solutions:contents:card-2:link-url')}
                      className="link-to-courses"
                    >
                      {t('Solutions:contents:card-2:link-text')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="lead lead--solutions text-center margin-bottom-40">
            <p
              dangerouslySetInnerHTML={{__html: t('Solutions:contents:text-2')}}
            />
          </div> */}
            <div className="row margin-bottom-40">
              <div className="col-md-6 sm-margin-bottom-20">
                <div className="service-block-v8">
                  <i className="icon-screen-desktop"></i>
                  <div className="service-block-desc">
                    <h3>{t('Solutions:contents:card-3:title')}</h3>
                    <p>{t('Solutions:contents:card-3:text')}</p>
                    <ul>
                      <li>{t('Solutions:contents:card-3:item-1')}</li>
                      <li>{t('Solutions:contents:card-3:item-2')}</li>
                      <li>{t('Solutions:contents:card-3:item-3')}</li>
                    </ul>
                    <hr />
                    <Link
                      to={t('Solutions:contents:card-3:link-url')}
                      className="link-to-courses"
                    >
                      {t('Solutions:contents:card-3:link-text')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 sm-margin-bottom-20">
                <div className="service-block-v8">
                  <i className="icon-screen-desktop"></i>
                  <div className="service-block-desc">
                    <h3>{t('Solutions:contents:card-4:title')}</h3>
                    <p>{t('Solutions:contents:card-4:text')}</p>
                    <ul>
                      <li>{t('Solutions:contents:card-4:item-1')}</li>
                      <li>{t('Solutions:contents:card-4:item-2')}</li>
                      <li>{t('Solutions:contents:card-4:item-3')}</li>
                      <li>{t('Solutions:contents:card-4:item-4')}</li>
                    </ul>
                    <hr />
                    <Link
                      to={t('Solutions:contents:card-4:link-url')}
                      className="link-to-courses"
                    >
                      {t('Solutions:contents:card-4:link-text')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="parallax-quote parallaxBg"
          style={{backgroundPosition: '50% 33px'}}
        >
          <div className="container">
            <div className="parallax-quote-in">
              <p
                dangerouslySetInnerHTML={{
                  __html: t('Solutions:contents:parallax:text'),
                }}
              />
              <a
                href={t('Solutions:contents:parallax:btn-url')}
                className="btn-u"
              >
                {t('Solutions:contents:parallax:btn-text')}
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', SolutionsPage);
